<template>
  <CommonLayout>
    <div class="profile">
      <div class="profile__body">
        <div class="profile__col">
          <div class="profile-photo">
            <img
              v-if="user.photo"
              class="profile-photo__pic"
              :src="userPhoto"
              width="268"
              height="268"
              alt=""
            />
            <img
              v-else-if="user.isCaptain"
              class="profile-photo__pic"
              src="@/assets/img/pic2/avatar-captain.svg"
              width="268"
              height="268"
              alt=""
            />
            <img
              v-else
              class="profile-photo__pic"
              src="@/assets/img/pic2/avatar-default.svg"
              width="268"
              height="268"
              alt=""
            />
          </div>
          <div class="profile-photo__foot">
            <button
              v-if="!isOtherProfile"
              @click="$modal.show('upload-photo')"
              class="profile-photo__button"
            >
              Изменить аватар
            </button>
            <div class="profile-list">
              <div class="profile-list__item">
                <div class="profile-list-coins">
                  <div class="profile-list-coins__title">GPB-coins</div>
                  <div class="profile-summary-coins">
                    <img
                      src="@/assets/img/pic2/coin.svg"
                      width="32"
                      height="32"
                      alt=""
                    />{{ user.coinsAcquired | priceFilter }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="profile__col">
          <h1 class="profile__title">Профиль игрока</h1>
          <div v-if="user.id" class="profile-info">
            <div class="profile-info__item profile-info__item_size_double">
              <div class="profile-info__label">Дефендер</div>
              <div class="profile-info__text">{{ user.fullName }}</div>
            </div>
            <div class="profile-info__item">
              <div class="profile-info__label">Ник</div>
              <div class="profile-info__text">
                <div class="nickname">
                  <form
                    v-if="editNickname"
                    class="nickname__edit"
                    @submit.prevent="updateNickname"
                  >
                    <div class="input-wraper">
                      <input
                        v-model.trim="newNickname"
                        class="nickname__edit-input text__input"
                        type="text"
                        placeholder="Введите ваш ник"
                      />
                      <button class="nickname__edit-button"></button>
                      <span
                        class="nickname__edit-cancel"
                        @click="hideEditNickname()"
                        >Отмена</span
                      >
                    </div>
                  </form>
                  <div v-else class="nickname__view">
                    <div
                      class="nickname__view-text limit"
                      :title="user.nickname"
                    >
                      {{ user.nickname ? user.nickname : user.fullName }}
                    </div>
                    <button
                      v-if="!isOtherProfile"
                      type="button"
                      class="nickname__view-button"
                      @click="showEditNickname"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="profile-info__item">
              <div class="profile-info__label">Эл. почта</div>
              <div class="profile-info__text">{{ user.eMail }}</div>
            </div>
            <div class="profile-info__item">
              <div class="profile-info__label">Специализация</div>
              <div class="profile-info__text">
                {{ user.userGroup | functionalGroupFilter }}
              </div>
            </div>
            <div class="profile-info__item">
              <div class="profile-info__label">Дата начала игры</div>
              <div class="profile-info__text">
                {{ user.firstQuestDate | formatDate }}
              </div>
            </div>
            <div v-if="user.teamName" class="profile-info__item">
              <div class="profile-info__label">Команда</div>
              <div class="profile-info__text">
                <router-link
                  :to="{ name: 'team', params: { teamID: user.teamId } }"
                  >{{ user.teamName }}</router-link
                >
              </div>
            </div>
            <div v-if="!isOtherProfile" class="profile-info__item">
              <div class="profile-info__label">&nbsp;</div>
              <div class="profile-info__text small">
                <router-link :to="{ name: 'settingsNotify' }"
                  >Настроить рассылку</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<script>
import { DeleteProfilePhoto, GetOtherPlayerProfile } from "@/api/Player";
import { islandList } from "@/data/game";
import CommonLayout from "@/layout/CommonLayout.vue";
import { getBaseURL } from "@/utils";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    CommonLayout,
  },
  computed: {
    ...mapGetters(["getProfile", "getGame", "getProfileCurrentIsland"]),
    userPhoto() {
      return getBaseURL() + this.user.photo;
    },
    currentWeek() {
      let week = this.getGame.currentWeek;
      if (week >= 35) {
        week = 36;
      } else {
        week = week + 1;
      }
      return week;
    },
    currentIsland() {
      const currentIsland = this.getProfileCurrentIsland.islandIdx;
      let data = {};

      if (currentIsland !== undefined) {
        islandList.forEach((island, index) => {
          if (index === currentIsland) {
            data.name = island.name;
            data.title = island.title;
          }
        });
      }

      return data;
    },
    user() {
      return this.isOtherProfile ? this.otherUser : this.getProfile;
    },
  },
  data() {
    return {
      newNickname: "",
      editNickname: false,
      tasks: [],
      isOtherProfile: false,
      otherUser: {},
    };
  },
  watch: {
    $route() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions(["USER_NICKNAME"]),
    ...mapMutations(["USER_DELETE_PHOTO"]),
    async init() {
      const { userID } = this.$route.params;
      if (userID) {
        this.isOtherProfile = true;
        this.otherUser = await this.fetchData(userID);
      } else {
        this.isOtherProfile = false;
      }
    },
    async fetchData(userID) {
      const resp = await GetOtherPlayerProfile({ playerId: userID });

      return resp.data;
    },
    showEditNickname() {
      this.newNickname = this.getProfile.nickname;
      this.editNickname = true;
    },
    hideEditNickname() {
      this.editNickname = false;
    },
    updateNickname() {
      const newNickname = this.newNickname;

      if (newNickname !== this.getProfile.nickname) {
        this.USER_NICKNAME(newNickname)
          .then(() => {
            this.hideEditNickname();
          })
          .catch(this.handleError);
      } else {
        this.hideEditNickname();
      }
    },
    onDeletePhoto() {
      DeleteProfilePhoto()
        .then(() => {
          this.USER_DELETE_PHOTO();
        })
        .catch(() => {
          this.$modal.show("message", {
            message: "Произошла ошибка, попробуйте позже",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
